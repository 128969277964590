body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: darkslateblue;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  display: block;
  margin: 1rem;
}

.title {
  position: fixed;
  width: 100%;
  padding: 0.5rem;
  font-size: 2rem;
  left: 0%;
  margin-top: 0%;
  background-color: #fffe5a;
  color: #f318f3;
  z-index: 1;
}

.home-container {
  margin-top: 4rem;
  background-color: darkslateblue;
  width: 100%;
}

.home-header {
  background-color: slategrey;
  margin-top: -1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.1rem;
  margin-bottom: 1rem;
  width: 100%;
  overflow: hidden;
}

.loading-container {
  margin-top: 30%;
  width: 100%;
}

.loading-text {
  font-size: 2rem;
}

.rotating-clown {
  font-size: 6rem;
  animation: rotation 2s infinite linear;
  margin: 0rem;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header-items {
  text-align: left;
}

.header-user {
  text-align: right;
}

.link-text {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(187, 85, 38);
}

a {
  color: rgb(187, 85, 38);
}

.new-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.new-text-button {
  border: solid;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
}

.reward {
  margin-top: 0;
  margin-bottom: -2rem;
  height: 1rem;
  width: 50%;
  margin-left: 65%;
  opacity: 0;
}

.reward-animation {
  animation: reward-keyframes 1s linear;
}

@keyframes reward-keyframes {
  0% {
    font-size: 1.5rem;
    opacity: 1;
    position: relative;
    top: 0rem;
  }
  100% {
    font-size: 0.7rem;
    opacity: 0;
    position: relative;
    top: -1rem;
    letter-spacing: 1rem;
  }
}

.new-video-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -1rem;
}

.check-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-check-label {
  font-size: 1rem;
}

.as-reply-to-container {
  display: flex;
  justify-content: space-between;
  margin: 0rem 2rem;
}

.as-reply-to-item {
  margin-top: 0rem;
}

.error-text {
  color: red;
}

.login-button {
  border: solid;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  width: 6rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.texts-container {
  padding: 0rem 1rem;
}

.content-item {
  margin-top: 0rem;
}

.content-item-reply {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.reply-bar {
  margin: 0rem;
}

.reply-button {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(187, 85, 38);
}

.chat-button {
  position: fixed;
  left: 2rem;
  top: 85%;
  border: solid slategrey;
  border-radius: 50%;
  background-color: white;
  padding: 0.3rem;
  width: 2.5rem;
  height: 2.5rem;
}

.chat-notification-dot {
  top: -370%;
  left: 40%;
  color: red;
  position: absolute;
  font-size: 6rem;
}

.chat-emoji {
  position: absolute;
  left: 0.5rem;
  top: -0.7rem;
  font-size: 1.5rem;
}

.chat-container {
  display: block;
  position: fixed;
  top: 20%;
  left: 10%;
  height: 60%;
  width: 80%;
  background-color: white;
  color: black;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  z-index: 1;
}

.long-chat-container {
  padding: 0rem 1rem;
}

.chat-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 2rem;
}

.chat-name {
  text-transform: uppercase;
}

.chat-option {
  text-decoration: underline;
  cursor: pointer;
}

.chat-option-disabled {
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;
}

.video-container {
  padding-top: 4rem;
  padding-bottom: 0.5rem;
  position: fixed;
  width: 100%;
  background-color: darkslateblue;
}

.video-frame {
  width: 95%;
  height: 30vh;
}

.video-title {
  text-align: left;
  margin: 0rem 2.5%;
}

.interactions-container {
  position: fixed;
  width: 100%;
  max-height: 40%;
  bottom: 4rem;
  z-index: -1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.comment-container {
  margin: 0rem;
  padding: 0.5rem 1rem;
}

.comment {
  text-align: right;
  padding: 0rem;
  margin: 0rem;
}

.new-interaction-container {
  position: fixed;
  width: 100%;
  bottom: 0rem;
  background-color: slategrey;
}

.red-overlay {
  background-color: rgba(255, 0, 0, 0.5);
  width: 7%;
  height: 2rem;
  position: absolute;
  left: 47%;
}

.user-container {
  margin-top: 4rem;
}

.user-name-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.user-items-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.user-items-container p {
  margin: 0rem;
}

.faction-badge {
  margin-top: 1rem;
  margin-left: 1rem;
  background-color: white;
  width: 2rem;
  height: 2.5rem;
  border: black solid;
  border-radius: 0.5rem;
}

.faction-badge-flag {
  margin: 0rem;
}

.faction-badge-toilet {
  margin-top: -0.5rem;
}

.wall-container {
  width: 100%;
  overflow: hidden;
}

.draggable {
  position: relative;
  margin: 0rem;
  padding: 0rem;
  width: auto;
  z-index: 1;
}

.wall-input {
  margin: 0;
  padding: 0;
  z-index: 1;
  background: none;
  color: white;
  border: none;
}

.wall-input:focus-visible {
  outline: none;
}

.wall-toolbar {
  margin-top: 3rem;
  background-color: slategrey;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colours {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.sticker {
  position: absolute;
}

.new-sticker-button {
  border: solid;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  width: fit-content;
  margin-top: 0rem;
}

.email-container {
  padding-top: 4rem;
}

.email-input {
  width: 90%;
}

.email-subject-radio {
  display: inline;
}

.invite-container {
  padding-top: 4rem;
}

.invite-input {
  width: 90%;
}

.map-container {
  padding-top: 4rem;
}

.grey-background {
  background-color: slategrey;
}

.clown-title {
  margin: 0;
  font-size: 6rem;
}

.home-text {
  padding: 0.5rem;
}

.about-container {
  padding-top: 4rem;
}
